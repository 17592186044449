// States & Config
import states from './settings-section-modifier.state.js'
import workspaceStates from '@/components/parts/workspace/workspace.state.js'

// Services
import { lockEditPanel, workSpaceMode } from '@/services/states/states.service'
import { applyDrag, componentBuilder } from '@/services/utils/utils.service'

// Components
import { Container, Draggable } from 'vue-smooth-dnd'
import MdiDelete from 'vue-material-design-icons/Delete.vue'
import MdiCursorMove from 'vue-material-design-icons/CursorMove.vue'
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'
import inputNumberToString from '@/components/standalone/input-number-to-string/Input-number-to-string.vue'
import AlignVerticalModifier from '@/components/standalone/align-vertical-modifier/Align-vertical-modifier.vue'

// Libraries
import {
  get as _get,
  sumBy as _sumBy,
  forEach as _forEach,
  cloneDeep as _cloneDeep,
  findIndex as _findIndex
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-section'

// Vue@Properties
const props = {
  conf: Object,
  mjml: Object
}
// Vue@Properties

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states),
    workspaceStates
  }
}
// Vue@data

// Vue@subComponents
const components = {
  AlignVerticalModifier,
  inputNumberToString,
  custColorPicker,
  MdiCursorMove,
  Container,
  Draggable,
  MdiDelete
}
// Vue@subComponents

// Methods
const methods = {
  // Func@getPayload
  /**
   * Get DND payload
   * @param  {Number} index (position)
   *
   * @return {Object}       (payload)
   */
  getPayload (index) {
    return this.columns[index]
  },
  // Func@getPayload

  // Func@onDrop
  /**
   * To get the droped list and replace the current
   * @param  {Object} dropResult (D&D params)
   */
  onDrop (dropResult) {
    if (this.states.reverseColumns && !this.isMobileMode) {
      dropResult.removedIndex = (this.columns.length - 1) - dropResult.removedIndex
      dropResult.addedIndex = (this.columns.length - 1) - dropResult.addedIndex
    }

    const newArray = applyDrag(this.columns, dropResult)
    this.columns.splice(0, this.columns.length, ...newArray)
  },
  // Func@onDrop

  // Func@deleteColumn
  /**
   * Remove selected structure from the data
   * @param  {Int}   index  (Position)
   */
  deleteColumn (index) {
    if (this.columns.length === 1) return

    const custumWidth = _get(this.columns[index], 'attributes.width', null)
    this.columns.splice(index, 1)
    switch (true) {
      // Is section or is auto-calculated width
      case this.isSection || !custumWidth: {
        this.checkColumnList()
        return
      }

      // Result is one column
      case this.columns.length === 1: {
        this.columns[0].attributes.width = ''
        this.checkColumnList()
        return
      }

      // Result is more than one column
      default: {
        const newCurrent = this.columns[index] || this.columns[index - 1]
        newCurrent.attributes.width = `${parseInt(newCurrent.attributes.width) + parseInt(custumWidth)}%`
        this.checkColumnList()
      }
    }
  },
  // Func@deleteColumn

  // Func@addColumn
  /**
   * Add columns to selected row
   */
  async addColumn () {
    if (this.columns.length === this.states.columnsLimit) return

    const column = await componentBuilder({
      withId: true,
      componentId: 'mj-column',
      overwrite: {
        'mj-column': {
          attributes: {
            activeStateIndex: 0,
            states: [
              {
                default_operator: 'or',
                conditions: {},
                operations: []
              }
            ]
          },
          children: [[]]
        }
      }
    })
    const lastColumnIndex = this.columns.length - 1
    const lastColumCustomWidth = _get(this.columns[lastColumnIndex], 'attributes.width', null)

    if (!lastColumCustomWidth) {
      this.columns.push(column)
      this.checkColumnList()
      return
    }

    const newWidth = parseInt(lastColumCustomWidth, 10) / 2
    if (newWidth < 10) {
      this.checkColumnList(newWidth)
      return
    }

    this.columns[lastColumnIndex].attributes.width = `${Math.ceil(newWidth)}%`
    column.attributes.width = `${Math.floor(newWidth)}%`
    this.mjml.children.push(column)
    this.checkColumnList()
  },
  // Func@addColumn

  // Func@checkColumnList
  /**
   * Check column size after a user update then display a message
   * and lock edit panel in case of:
   * - Mixt between the width types  (defined and automatic)
   * - Sum of the defined width not equal to 100%
   * - Max column quantity
   * - Min width requested to add a column
   */
  checkColumnList (newWidth) {
    const sum = _sumBy(
      this.columns,
      column => parseInt(_get(column, 'attributes.width', 0), 10)
    )

    const body = this.bodyCalculation
    const rest = this.states.unitType === '%' ? sum % 100 : sum % body.width

    switch (true) {
      case !this.allIsCustom && !this.allIsAuto: {
        this.$set(this.states.alert.columnSize, 'type', 'mixtMode')
        this.$set(this.states.alert.columnSize, 'priority', 'error')
        this.$set(this.states.alert.columnSize, 'active', true)
        lockEditPanel(true, 'structure')
        return
      }

      case !this.allIsAuto && rest !== 0: {
        this.$set(this.states.alert.columnSize, 'type', 'sum')
        this.$set(this.states.alert.columnSize, 'messageVar', `${sum}${this.states.unitType}`)
        this.$set(this.states.alert.columnSize, 'priority', 'error')
        this.$set(this.states.alert.columnSize, 'active', true)
        lockEditPanel(true, 'structure')
        return
      }

      case this.columns.length === this.states.columnsLimit: {
        this.$set(this.states.alert.columnSize, 'type', 'limit')
        this.$set(this.states.alert.columnSize, 'priority', 'info')
        this.$set(this.states.alert.columnSize, 'active', true)
        lockEditPanel(false)
        return
      }

      case newWidth && newWidth < 10: {
        this.$set(this.states.alert.columnSize, 'type', 'minSize')
        this.$set(this.states.alert.columnSize, 'messageVar', this.states.columnsLimit - this.columns.length)
        this.$set(this.states.alert.columnSize, 'priority', 'info')
        this.$set(this.states.alert.columnSize, 'active', true)
        lockEditPanel(false)
        return
      }

      case !this.allIsAuto: {
        this.$set(this.states.alert.columnSize, 'type', 'noDuplicate')
        this.$set(this.states.alert.columnSize, 'priority', 'info')
        this.$set(this.states.alert.columnSize, 'active', true)
        lockEditPanel(false)
        return
      }

      default: {
        this.$set(this.states.alert.columnSize, 'active', false)
        lockEditPanel(false)
      }
    }
  },
  // Func@checkColumnList

  // Func@setAutoSize
  /**
   * Set quickly all columns width to automatic
   */
  setAutoSize () {
    _forEach(this.columns, column => {
      column.attributes.width = ''
    })
    this.checkColumnList()
  },
  // Func@setAutoSize

  // Func@setColumnValign
  /**
   * Columns vertical align
   * @param {String} alignment (css attribute 'top', 'middle', 'bottom')
   */
  setColumnValign (alignment) {
    _forEach(this.columns, column => {
      column.attributes['vertical-align'] = alignment
    })
  },
  // Func@setColumnValign

  // Func@reversColumns
  changeOrderColumns () {
    const orderColumns = this.states.reverseColumns ? 'rtl' : 'ltr'
    this.mjml.attributes.direction = orderColumns
    this.mjml.children.reverse()
  }
}

// Computed methods
const computed = {

  // Func@templateWidthIsPercent
  /**
   * CHeck if template width is automatique
   * @return {Boolean}
   */
  templateWidthIsPercent () {
    const bodyAttr = _get(this.workspaceStates, 'template.children.0.attributes', {})
    return bodyAttr.width.slice(-1) === '%'
  },
  // Func@templateWidthIsPercent

  // Func@bodyCalculation
  /**
   * Body calculation details for unit type conversion
   * @returns {Object} (width of parent container, col divisor, rest)
   */
  bodyCalculation () {
    const bodyAttr = _get(this.workspaceStates, 'template.children.0.attributes', {})
    const colNumber = this.columns.length

    let divisor
    let rest = 0
    let margeAndBorders = 0
    let width = bodyAttr.width.split('px').shift()

    if (_get(this.conf, 'parent.border')) margeAndBorders += parseInt(this.conf.parent.border.split('px').shift()) * 2
    if (_get(this.mjml, 'attributes.border')) margeAndBorders += parseInt(this.mjml.attributes.border.split('px').shift()) * 2
    if (_get(this.mjml, 'attributes.padding')) {
      const paddingSplit = this.mjml.attributes.padding.split(' ')
      margeAndBorders += (parseInt(paddingSplit[1]) + parseInt(paddingSplit[3]))
    }

    if (this.conf.isNested && this.conf.parent.width) {
      const parentWidth = this.conf.parent.width
      switch (true) {
        case parentWidth.includes('px'):
          width = parentWidth.split('px').shift() - margeAndBorders
          break
        case parentWidth.includes('%'):
          divisor = parentWidth.split('%').shift() / 100
          width = width * divisor - margeAndBorders
          rest = width % (width * (1 / colNumber))
          break
      }
    }

    return {
      width,
      divisor,
      rest: parseInt(rest)
    }
  },
  // Func@bodyCalculation

  // Func@columnUnitType
  /**
   * Check if all columns are with automatic width
   * @return {Boolean}
   */
  columnUnitType: {
    get () {
      const widthSample = _get(this.columns, '0.attributes.width', '')
      const unitType = widthSample.slice(-2) === 'px' ? 'px' : '%'
      this.states.unitType = unitType
      return unitType
    },
    set (value) {
      if (value === this.states.unitType) return
      if (!this.mjml.attributes.responsive && value === 'px') this.mjml.attributes.responsive = true
      this.states.unitType = value

      const body = this.bodyCalculation
      const colNumber = this.columns.length
      const isAuto = this.columns
        .map(col => col.attributes.width)
        .every((val, i, arr) => val === arr[0])

      _forEach(this.columns, (column, index) => {
        if (value === 'px') {
          const rest = index === 0 ? body.rest : 0
          const divisor = isAuto ? (1 / colNumber) : (column.attributes.width.split('%').shift() / 100)
          column.attributes.width = `${parseInt((body.width * divisor)) + rest}px`
        } else {
          const currentValue = column.attributes.width.split('px').shift()
          column.attributes.width = isAuto ? '' : `${(100 / body.width) * currentValue}%`
        }
      })
    }
  },
  // Func@columnUnitType

  // Func@allIsAuto
  /**
   * Check if all columns are with automatic width
   * @return {Boolean}
   */
  allIsAuto () {
    return _findIndex(
      this.columns,
      col => _get(col, 'attributes.width.length', 0) > 1
    ) === -1
  },
  // Func@allIsAuto

  // Func@allIsCustom
  /**
   * Check if all columns are with defined width
   * @return {Boolean}
   */
  allIsCustom () {
    return _findIndex(
      this.columns,
      col => !(_get(col, 'attributes.width.length', 0) > 1)
    ) === -1
  },
  // Func@allIsCustom

  // Func@getSizePlaceholder
  /**
   * Define column width field placeholder to
   * inform user in case of automatic width
   * @return {String} (auto - xx.xx%)
   */
  getSizePlaceholder () {
    const autoSizePercent = 100 / this.columns.length
    return `${this.$t('t_sizePlaceholder_')} - ${autoSizePercent.toFixed(2)}%`
  },
  // Func@getSizePlaceholder

  // Func@isMaxColumn
  /**
   * Test if is max column
   * @return {Boolean}
   */
  isMaxColumn () {
    return this.columns.length === this.states.columnsLimit
  },
  // Func@isMaxColumn

  // Func@hasAlertMessage
  /**
   * Test if alert message is enabled
   * @return {Boolean}
   */
  hasAlertMessage () {
    return this.states.alert.columnSize.active &&
           this.states.alert.columnSize.type !== 'noDuplicate'
  },
  // Func@hasAlertMessage

  // Func@columns
  /**
   * Columns of the row
   * @return {Array} (columns)
   */
  columns () {
    return this.mjml.children
  },
  // Func@columns

  // Func@columnsWithReverse
  /**
   * Columns of the row but with reverse feature
   * only for table display
   * @return {Array} (columns)
   */
  columnsWithReverse () {
    return this.states.reverseColumns && !this.isMobileMode ? this.columns.slice().reverse() : this.columns
  },
  // Func@columnsWithReverse

  // Func@isMobileMode
  /**
   * Current app mode
   * @return {Boolean} true if app is in mobile mode
   */
  isMobileMode () {
    return workSpaceMode().get() === 'mobile'
  },
  // Func@isMobileMode
}

// Vue@watchMjml
const watch = {
  'mjml.children': {
    handler () {
      this.checkColumnList()
    }
  }
}

const mounted = function () {
  this.checkColumnList()
  this.states.reverseColumns = this.mjml.attributes.direction === 'rtl'
}

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  mounted,
  computed,
  components
}
